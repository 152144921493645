<template>
  <div class="flex">
  <vx-card title="Team hinzufügen" class="mr-4 lg:w-1/2">
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-1">
        <div class="vx-row mb-1">
          <div class="vx-col  w-full mb-4">
            <vs-input class="w-full" v-validate="'required|min:3'"  label-placeholder="Name" name="teamName" v-model="teamName"/>
            <span class="text-danger text-sm">{{ errors.first('teamName') }}</span>
          </div>
        </div>
      </div>
      </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="createTeam">Hinzufügen</vs-button>
        <vs-button color="warning" type="border" class="mb-2" to="/teams">Abbrechen</vs-button>
      </div>
    </div>
  </vx-card>
  </div>
</template>

<script>
import ApiService from "../../../api";

export default {
  name: "AddTeams",
  data() {
    return {
      teamName:'',
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.teamName !== ''
    },
  },

 methods: {
    createTeam() {
      if (!this.validateForm) return

      this.$vs.loading()
      const payload = {
        name: this.teamName
      }

      ApiService.post('teams', payload).then((response) => {

        this.$vs.loading.close()

        if(response.data.status === 'success') {
          return this.$vs.notify({
                text: 'Das Team wurde erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch((response) => {

       this.$vs.loading.close()

       this.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.$router.push('/teams')
    },
  }
}
</script>

<style scoped>

</style>